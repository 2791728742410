import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faMicrophoneLines, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'

import styles from './TopBar.module.scss'

import Config from '../../config'

const TopBar = ({}) => {
  const handleExit = () => {
    window.location.href = 'https://google.com'
  }

  const handleOnClick = (href: string, name: string) => {
    window.umami?.track(`Social link: ${name}`)

    window.open(href, '_blank')
  }

  return (
    <div className={styles.topBarPlaceholder}>
      <div className={styles.topBar}>
        <div className={styles.info}>
          <Link href="/contact-us" className={styles.infoContact}>
            <a className={styles.infoContact}>Book an appointment</a>
          </Link>
          <Link href="/contact-us" className={styles.infoContactMobile}>
            <a className={styles.infoContactMobile}>Contact</a>
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span className={styles.infoCall}>Call: &nbsp;</span><a href={`tel:${Config.phone}`}>{Config.phoneDisplay}</a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href={`mailto:${Config.email}`}>info@breakeven.org.uk</a>
        </div>
        <div className={styles.social}>
          <a href="/social/links/instagram" target="_blank" onClick={evt => {
            evt.preventDefault()
            
            handleOnClick('/social/links/instagram', 'Instagram')
          }}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="/social/links/x" target="_blank" onClick={evt => {
            evt.preventDefault()
            
            handleOnClick('/social/links/x', 'X')
          }}>
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="/social/links/facebook" target="_blank" onClick={evt => {
            evt.preventDefault()
            
            handleOnClick('/social/links/facebook', 'Facebook')
          }}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="/social/links/linkedin" target="_blank" onClick={evt => {
            evt.preventDefault()
            
            handleOnClick('/social/links/linkedin', 'LinkedIn')
          }}>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <Link href="/podcast">
            <a>
              <FontAwesomeIcon icon={faMicrophoneLines} />
            </a>
          </Link>
          <div className={styles.exitButton} onClick={handleExit}>
            <FontAwesomeIcon icon={faTimesCircle} />
            <span>EXIT</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar