import React, { useRef, useEffect, useState, forwardRef, ReactNode } from 'react'
import Head from 'next/head'
import { Scrollbars } from 'rc-scrollbars'
import { config } from '@fortawesome/fontawesome-svg-core'
import { AnimatePresence } from 'framer-motion'
import NProgress from 'nprogress'
import { useRouter } from 'next/router'
import Script from 'next/script'

config.autoAddCss = false

import MainNav, { MainNavElement } from '../components/MainNav'
import TopBar from '../components/TopBar'
import useViewport from '../hooks/useViewport'
import ScrollToTop from '../components/ScrollToTop'

import '@fortawesome/fontawesome-svg-core/styles.css'
import 'nprogress/nprogress.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '../styles/globals.scss'

function MyApp({ Component, pageProps }) {
  const [isNavHidden, setIsNavHidden] = useState<boolean>(false)
  const [isNavFloating, setIsNavFloating] = useState<boolean>(false)
  const [isScrollToTopHidden, setIsScrollToTopHidden] = useState<boolean>(true)
  const [isBlockScroll, setIsBlockScroll] = useState<boolean>(false)

  const viewport = useViewport()
  const router = useRouter()

  const scrollRef = useRef<Scrollbars>()
  const mainNavRef = useRef<MainNavElement>()
  const scrollAnimationFrameRef = useRef<number>()
  const scrollPositionRef = useRef<number>(0)

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    if (window) {
      window.addEventListener('scroll', handleOnScroll)
    }

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)

      if (window) {
        window.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (router.isReady) {
      if (router.pathname === '/_error' || router.pathname === '/404') {
        router.replace(router.pathname);
      } else {
        router.replace(router.asPath);
      }
    }
  }, [router.isReady])

  useEffect(() => {
    if (!scrollRef.current) {
      const $body = document.querySelector('body')

      if (isBlockScroll) {
        $body.classList.add('blockScroll')
        $body.style.top = `-${scrollPositionRef.current}px`
      } else {
        $body.classList.remove('blockScroll')
        $body.style.removeProperty('top')

        setScrollTop(scrollPositionRef.current)
      }
    }
  }, [isBlockScroll])

  useEffect(() => {
    const el = document.getElementById('purechat-container')

    if (el) {
      if (isScrollToTopHidden) {
        el.classList.remove('move')
      } else {
        el.classList.add('move')
      }
    }
  }, [isScrollToTopHidden])

  const routeChange = () => {
    // Temporary fix to avoid flash of unstyled content
    // during route transitions. Keep an eye on this
    // issue and remove this code when resolved:
    // https://github.com/vercel/next.js/issues/17464
    const tempFix = () => {
      const elements = document.querySelectorAll('style[media="x"]')
      elements.forEach((elem) => elem.removeAttribute('media'))
      //setTimeout(() => {
        //elements.forEach((elem) => elem.remove())
      //}, 1000)
    }
    tempFix()
  }

  const handleOnContentScroll = (top = 0, duration = 600, scrollId = null) => {
    let height = getScrollHeight()
    let initialScroll = getScrollTop()
    let start
    let position

    const maxScroll = height - document.documentElement.clientHeight
    const newTop = scrollId ? (document.querySelector(`[data-scroll-id="${scrollId}"]`)?.[0]?.offsetTop + top) : top
    const amountToScroll = initialScroll - newTop

    const step = timestamp => {
      if (start === undefined) {
        start = timestamp
      }

      const elapsed = timestamp - start
      const relativeProgress = elapsed / duration
      const easedProgress = 1 - Math.pow(1 - relativeProgress, 4)

      position = initialScroll - amountToScroll * Math.min(easedProgress, 1)

      setScrollTop(position)

      if (initialScroll !== maxScroll && getScrollTop() === maxScroll) {
        cancelAnimationFrame(scrollAnimationFrameRef.current)

        return
      }

      if (elapsed < duration) {
        scrollAnimationFrameRef.current = requestAnimationFrame(step)
      }
    }

    scrollAnimationFrameRef.current = requestAnimationFrame(step)
  }

  const setScrollTop = (top = 0) => {
    if (scrollRef.current && scrollRef.current.scrollTop) {
      scrollRef.current.scrollTop(top)
    } else {
      window.scrollTo(0, top)
    }
  }

  const getScrollTop = () => {
    if (scrollRef.current && scrollRef.current.getScrollTop) {
      return scrollRef.current.getScrollTop()
    } else {
      return window.scrollY
    }
  }

  const getScrollHeight = () => {
    if (scrollRef.current && scrollRef.current.getScrollHeight) {
      return scrollRef.current.getScrollHeight()
    } else {
      return document.documentElement.scrollHeight
    }
  }

  const handleRouteChangeStart = (url, args) => {
    routeChange()
    NProgress.start()
    mainNavRef.current?.hide()

    if (router.asPath === url) {
      setScrollTop(0)
    }
  }
  
  const handleRouteChangeComplete = (url, args) => {
    routeChange()
    NProgress.done()
  }
  
  const handleRouteChangeError = (url, args) => {
    NProgress.done()
  }

  const handleOnScroll = () => {
    var top = getScrollTop()

    if (top > scrollPositionRef.current) {
      handleScrollDown(top)
    } else {
      handleScrollUp(top)
    }

    const scrollEvent = new CustomEvent('onPageScroll', {
      detail: {
        top,
        direction: top > scrollPositionRef.current ? 'down' : 'up'
      }
    })

    document.dispatchEvent(scrollEvent)

    scrollPositionRef.current = top
  }

  const handleScrollDown = top => {
    if (top > (document.documentElement.clientHeight / 2)) {
      setIsNavHidden(true)
    } else {
      setIsNavHidden(false)
    }

    if (top > document.documentElement.clientHeight) {
      setIsScrollToTopHidden(false)
    } else {
      setIsScrollToTopHidden(true)
    }

    if (top > 0) {
      setIsNavFloating(true)
    } else {
      setIsNavFloating(false)
    }
  }

  const handleScrollUp = top => {
    setIsNavHidden(false)

    if (top > document.documentElement.clientHeight) {
      setIsScrollToTopHidden(false)
    } else {
      setIsScrollToTopHidden(true)
    }

    if (top > (viewport.width > 767 ? 45 : 0)) {
      setIsNavFloating(true)
    } else {
      setIsNavFloating(false)
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Head>
      <ScrollView 
        ref={scrollRef}
        onScroll={handleOnScroll}
        disabled={isBlockScroll}
      >
        <TopBar />
        <MainNav ref={mainNavRef} isHidden={!isBlockScroll && isNavHidden} isFloating={isBlockScroll || isNavFloating} onBlockScroll={setIsBlockScroll} />
        <div id="main">
          <AnimatePresence
            exitBeforeEnter
            initial={false}
            onExitComplete={() => setScrollTop(0)}
          >
            <Component {...pageProps} onScroll={handleOnContentScroll} canonical={router.route} key={router.route} />
          </AnimatePresence>
        </div>
        <ScrollToTop isHidden={isScrollToTopHidden} onScrollToTop={() => handleOnContentScroll(0)} />
      </ScrollView>
      <Script defer src="https://umami.develapp.app/script.js" data-website-id="3b54b1d4-b057-4b79-96d9-fd66097f6240" data-domains="breakeven.org.uk,www.breakeven.org.uk" />
      <Script id="pure-chat" strategy="lazyOnload" data-cfasync='false'>{`window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); script.onreadystatechange = script.onload = function (e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new PCWidget({c: 'b6d14673-9857-4d6b-9964-7a1167ad8f28', f: true }); done = true; } }; })();`}</Script>
    </>
  )
}

type ScrollViewProps = {
  children?: ReactNode
  onScroll?: (evt: React.UIEvent<HTMLElement>) => void
  disabled?: boolean
}

const ScrollView = forwardRef<Scrollbars, ScrollViewProps>(({
  children,
  onScroll,
  disabled
}, ref) => {
  const viewport = useViewport()

  if (viewport.width < 1365) {
    return children
  }

  if (disabled) {
    return (
      <div style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
      }}>
        {children}
      </div>
    )
  }

  return (
    <Scrollbars 
      ref={ref}
      id="main-scroll"
      universal
      style={{ 
        width: '100vw', 
        height: '100vh' 
      }}
      renderTrackVertical={props => <div {...props} className="scroll-track-vertical"/>}
      renderTrackHorizontal={props => <div {...props} className="scroll-track-horizontal"/>}  
      onScroll={onScroll}
    >
      {children}
    </Scrollbars>
  )
})

ScrollView.displayName = 'ScrollView'

export default MyApp
